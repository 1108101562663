export function formatNumber(str) {
    //console.log('str',str)
    
    var valueFormatted = '';
    str? str.toString().includes(',')
    ?valueFormatted = str.toString().replace(/,/g, ' ').replace(/\./g, ',')
    :valueFormatted = str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    :valueFormatted="0";
    
    return valueFormatted;
}

export function formatDateFr(value){
  if(value){
      const year = value.getFullYear();
      let month =value.getMonth() + 1;
      month = month<10?'0'+month:month;
      let day = value.getDate();  
      day = day<10?'0'+day:day;      
      return day+'/'+month+'/'+year;
      
  }
}

export const validate_phone_number = (myvalue,tag,len=10) => { 
 
    var phone = myvalue;  
    phone = phone.replace(/\s+/g, '');
      var temp = phone.substring(phone.length-1,phone.length);
      if(phone.search("[^0-9]")>=0){ 
        if(temp!='' && temp<=9 && phone.length<=len){
            // 
        }else{
          if(typeof(temp)==='string'){      
            tag.value = phone.substring(0,phone.length-1);             
          }
          if(phone.substring(0,phone.length-1).search("[^0-9]")>=0){
            tag.value='';                     
          }
          
                    
        }         
      }
      if(phone.length>len){        
        tag.value = phone.substring(0,phone.length-1);  
      }
  }

export  const showToastOld = (message) => {
    const toastElement = document.getElementById("liveToast");    
    localStorage.setItem('message', message);
    if (toastElement) {
      const toastBootstrap = new window.bootstrap.Toast(toastElement);
      toastBootstrap.show();
    }
    // localStorage.setItem('message', null);
  };

  export  const showToast  = (message,color) =>  {
    var x = document.getElementById("snackbar");
    x.style.backgroundColor = color;
    x.innerText=message;
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }

export  const checkAmount = (jsonArray)=>{
    let i = 0;
    for (const row of jsonArray) {    
      if(parseFloat(row.amount)<=0){      
        i++;
      }     
    }
    return i;
}

export const checkDuplicateTel = (jsonArray)=>{
    let tempJsonArray = jsonArray;
     let totalAmount = 0;
     let duplicateArray = [];
     let i = 0;
     let k = 0;
    for (const row of jsonArray) {    
      let tempMobile = row.mobile.trim();
      let j = k+1;
      while(j<tempJsonArray.length){
        if(tempMobile == tempJsonArray[j].mobile.trim()){
          i++;
          totalAmount+= parseInt(tempJsonArray[j].amount);
          duplicateArray.push(tempJsonArray[j]);
          tempJsonArray.splice(j, 1);
        }
        j++;
      } 
      k++;
    }
    return [i,totalAmount];
}

export const checkDuplicateTelOld = (jsonArray)=>{
  let tempJsonArray = jsonArray;
  // tempJsonArray.splice(0, 1);
   let duplicateArray = [];
   let i = 0;
   let k = 0;
  for (const row of jsonArray) {    
    let tempMobile = row.mobile.trim();
    let j = k+1;
    while(j<tempJsonArray.length){
      if(tempMobile == tempJsonArray[j].mobile.trim()){
        i++;
        duplicateArray.push(tempJsonArray[j]);
        tempJsonArray.splice(j, 1);
      }
      j++;
    } 
    k++;
  }
  return i;
}

export const checkTelSize = (jsonArray,phoneMask)=>{
  
  let i = 0;
    for (const row of jsonArray) {    
      if(row.mobile.length!==phoneMask){      
        i++;
      }     
    }
    return i;
}

export const checkTelFormat = (jsonArray)=>{
  
    let i = 0;
    for (const row of jsonArray) {    
       let mobile = row.mobile.trim().split("");   //
       let j=0;  
       while(j<mobile.length){
          if(parseInt(mobile[j])>=0){ 
            //
            
          }else{
            alert(mobile[j]);
            i++;
            break;
          }
          j++;
       }
    }
    return i;
}



export const checkTelOperatorName = (jsonArray,operators)=>{  
    let i = 0;
    for (const row of jsonArray) { 
      let temp = operators.filter( operator => operator.mnoName == row.operator);           
      if(temp.length==0){      
        i++;        
      }     
    }
    return i;
}