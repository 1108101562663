<template>
  <div class="clearfix"  v-if="!pageLoad">
      <div class="spinner-grow text-danger " style="width: 3rem; height: 3rem;margin-top:25vh; margin-left:42%; margin-bottom:5vh" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </div>
  <div v-if="pageLoad" class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <button
              class="default-outline-btn text-black bg-transparent position-relative transition fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
              type="button"
              @click="exportExcel"
              
            >
            <!-- text-black bg-transparent -->
              Excel
              <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
            </button>

        
      </div>
      <div class="d-flex align-items-center">

           
            <button
              class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#companyModal"
              style="background-color: #EB9355;"
              @click="initField"
            >
              Ajouter contact
              <i class="flaticon-plus position-relative ms-5 fs-12"></i>
            </button>


      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
              Code 
            </th> 
            <th  v-if="current_permissions('ADMIN')"
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Organisation
              </th> 
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Nom
              </th> 
             
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Prénom (s)
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                numéro de téléphone
              </th>
             
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Status
              </th> -->
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr   v-for="(reloading) in contacts" :key="reloading.code">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ reloading.code }}
              </td> 
              <td v-if="current_permissions('ADMIN')" class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ reloading.organization.name }}
              </td>              
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ reloading.first_name }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ reloading.last_name }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">                
                {{ reloading.contact}}
              </td>          
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
                <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">

                    <li  >
                      <a
                        class="dropdown-item d-flex align-items-center"  style=""
                        href="javascript:void(0);"                        
                        data-bs-toggle="modal"
                        data-bs-target="#companyModal1"
                        @click="showTransfert(reloading.code)"
                        ><i
                          class="flaticon-file-1 position-relative ms-5 top-2 fs-15"
                        ></i>                       
                        <span   class="">Transfert</span>                        
                      </a>
                    </li>     
                   
                    <li> 
                      <a
                        class="dropdown-item d-flex align-items-center"  style="color: red;"
                        href="javascript:void(0);"
                         data-bs-toggle="modal"
                        data-bs-target="#filterLeadModal"
                        @click="showScreen(reloading.code)"                         
                        ><i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        <span   class="">Supprimer</span>
                        
                      </a>
                    </li> 
                    
                    <li  >
                      <a
                        class="dropdown-item d-flex align-items-center"  style=""
                        href="javascript:void(0);"                        
                        data-bs-toggle="modal"
                        data-bs-target="#companyModal"
                        @click="showReloading(reloading.code)"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>                       
                        <span   class="">Modifier</span>                        
                      </a>
                    </li>                           
                  </ul>
                </div>
              </td>
            </tr>
                      
          </tbody>
        </table>
      </div>
      <div
      v-if="total>0" class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center"
      >
        <p v-if="total>10" class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ page*10 }}</span> out of
          <span class="fw-bold">{{ total }}</span> resultats
        </p>
        <p v-if="total<=10"  class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ total }}</span> out of
          <span class="fw-bold">{{ total }}</span> resultats
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li  v-if="(page-1)>0" class="page-item">
              <a class="page-link" @click="getContacts(page-1)" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
           
            <li v-for="(numPage) in numPages" :key="numPage" class="page-item">              
              <a  v-if="numPage==page" class="page-link  active" href="#">{{ numPage}}</a>
              <a v-else class="page-link" @click="getContacts(numPage)">{{ numPage}}</a>
            </li>
           
            <li v-if="(page+1)<=numPages"  class="page-item">
              <a class="page-link"  @click="getContacts(page+1)" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>

  <div class="modal fade" id="filterLeadModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1  class="modal-title fs-5">Alert</h1>                
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cancel()"></button>
            </div>
            <div class="modal-body">
                      <div style="">
                        <h6  v-if="errors==''" class="text-red fw-bold mb-0 text-center" style="color: red;">{{ 'Voulez-vous vraiment supprimer ce contact'}}</h6>

                                                
                      </div>
                      <div v-if="errors!==''">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">code: {{ errorId}}</h6>
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>              
                        
                      </div>
                     
            </div>
            <div class="modal-footer">                
                <button type="button" class="btn default-outline-btn bg-transparent " style="background-color: #FFF;"  data-bs-dismiss="modal" @click="cancel()">
                    Non
                </button>               
                <button  :disabled="!btnLoad" type="button" class="btn" style="background-color: #EB9355;" @click="deleteContact()">           
                  <span v-if="!btnLoad" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"  style="color: #FFF;"></span>
                  <span style="color: #FFF;">Oui</span> 
                </button>
            </div>
        </div>
    </div>
</div>
   
  <div class="modal fade" id="companyModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-lg modal-dialog-centered">     
        <div class="modal-content">
           <form  @submit.prevent="save">
            <div class="modal-header">
                <h1 class="modal-title fs-5">{{edit==0?'Ajouter un contact':'Modifier un contact'}}</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">                    
                      <div v-if="errors!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
                      </div>
                      <div v-if="success!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
                      </div>
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <div class="row">                            
                            
                            <div class="col-md-4">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Numéro de téléphone
                                </label>              
                                <div >  
                              
                                        <input 
                                          type="text"
                                          v-model="formValues.phone" 
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                         
                                          placeholder="ex: 0720230120"                                                                             
                                          :class="{ 'is-invalid': errorsBis['phone'] }"
                                          id="phone" 
                                          @keyup="handlePhone($event.target.value,$event.target)"                                          
                                          @blur="handlePhone($event.target.value,$event.target)"
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['phone']}}</span> 
                                </div>

                            </div>
                            <div class="col-md-4">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Nom
                                </label>              
                                <div >  
                                 
                                        <input 
                                          type="text"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                         
                                          placeholder="ex: BOLI"
                                          v-model="formValues.first_name"                                    
                                          :class="{ 'is-invalid': errorsBis['first_name'] }"
                                          id="phone"                                                                               
                                          @blur="validate('first_name')"  
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['first_name']}}</span> 
                                </div>

                            </div>
                            <div class="col-md-4">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Prénom(s)
                                </label>              
                                <div >  
                                 
                                        <input 
                                          type="text"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                         
                                          placeholder="ex: FRANK"
                                          v-model="formValues.last_name"                                   
                                          :class="{ 'is-invalid': errorsBis['last_name'] }"
                                          id="phone"                                                                                 
                                          @blur="validate('last_name')"  
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['last_name']}}</span> 
                                </div>

                            </div>
                          </div>
                        
                    

                    </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <button type="submit" class="btn  text-white" style="background-color: #EB9355;" >
                  <i class="flaticon-plus position-relative ms-5 fs-12" style="color: #FFF;"></i> 
                  {{edit==0?'Enregister':'Modifier'}}
                </button>
            </div>
            </form>
        </div>
      
    </div>
</div>
  
<div class="modal fade" id="companyModal1" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-lg modal-dialog-centered">     
        <div class="modal-content">
           <form  @submit.prevent="sendMoney">
            <div class="modal-header">
                <h1 class="modal-title fs-5">{{'Transferer de l\'argent'}}</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">    
                      <div  style="margin-bottom: 5%;">
                        <h6 class="text-black fw-bold mb-0 text-center">{{ 'SOLDE'}}</h6>
                        <h6 class="text-black fw-bold mb-0 text-center" style="color: red;">{{ payoutBalance?.toLocaleString()}} XOF</h6>
                      </div>                
                      <div v-if="errors!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
                      </div>
                      <div v-if="success!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
                      </div>
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <div class="row">                            
                            
                            <div class="col-md-4">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Numéro de téléphone
                                </label>              
                                <div >  
                              
                                        <input 
                                          type="text"
                                          v-model="formValues.phone" 
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                         
                                          placeholder="ex: 0720230120"                                                                             
                                          :class="{ 'is-invalid': errorsBis['phone'] }"
                                          id="phone" 
                                          @keyup="handlePhone($event.target.value,$event.target)"                                          
                                          @blur="handlePhone($event.target.value,$event.target)"
                                          readonly 
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['phone']}}</span> 
                                </div>

                            </div>
                            <div class="col-md-4">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Nom
                                </label>              
                                <div >  
                                 
                                        <input 
                                          type="text"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                         
                                          placeholder="ex: BOLI"
                                          v-model="formValues.first_name"                                    
                                          :class="{ 'is-invalid': errorsBis['first_name'] }"
                                          id="phone"                                                                               
                                          @blur="validate('first_name')"  
                                          readonly 
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['first_name']}}</span> 
                                </div>

                            </div>
                            <div class="col-md-4">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Prénom(s)
                                </label>              
                                <div >  
                                 
                                        <input 
                                          type="text"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                         
                                          placeholder="ex: FRANK"
                                          v-model="formValues.last_name"                                   
                                          :class="{ 'is-invalid': errorsBis['last_name'] }"
                                          id="phone"                                                                                 
                                          @blur="validate('last_name')" 
                                          readonly 
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['last_name']}}</span> 
                                </div>

                            </div>
                          </div>
                          
                          <div class="row">
                                <div class="col-md-4">
                                    <label class="d-block text-black fw-semibold mb-10">
                                      Opérateurs 
                                    </label>
                                    <select  name="operator" id="operator" class="form-select fs-md-15 text-black shadow-none" @change="handleOperatorChange" required>
                                            <option value="">Selectionnez...</option>                 
                                            <option v-for="operator in operators" :value="operator.mnoName" :key="operator.code"> {{ operator.mnoName }}</option>
                                    </select> 
                                </div>
                                <div class="col-md-4">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    Montant
                                  </label>              
                                  <div >  
                                  
                                          <input 
                                            type="text"
                                            class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                         
                                            placeholder="ex: 2000"
                                            v-model="formValues.amount"                                   
                                            :class="{ 'is-invalid': errorsBis['amount'] }"
                                            id="amount"                                                                                 
                                            @blur="validate('amount')"  
                                            @keyup="validate_phone_number($event.target.value,$event.target)"   
                                          />
                                          <span class="invalid-feedback">{{ errorsBis['amount']}}</span> 
                                  </div>

                                </div>
                          </div>

                    </div>
            </div>
            <div class="modal-footer">                
                <button type="submit" class="btn  text-white" style="background-color: #EB9355;" >
                  <span v-if="!btnLoad" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"  style="color: #FFF;"></span>
                  <i class="flaticon-plus position-relative ms-5 fs-12" style="color: #FFF;"></i> 
                  {{'Transférer'}}
                </button>
            </div>
            </form>
        </div>
      
    </div>
</div>
<div id="snackbar"></div>
</template>

<script setup  lang="ts">
import {ref, onMounted,reactive,inject } from "vue";
import axios from 'axios';
import router from '../../../router/index'
import { useRouter } from 'vue-router';
import * as yup from 'yup';
import { Country,Fee,Operator,Organization,TypeFree,Contact, User } from "@/utils/Model";
import { getTypeFee } from "../../../utils/services";
import {current_permissions} from '../../../utils/permission';
import "intl-tel-input/build/css/intlTelInput.css";
import 'vue-tel-input/vue-tel-input.css';
import intlTelInput from 'intl-tel-input';
import {validate_phone_number,showToast} from "../../../utils/function";

let  iti ;
const backApi=inject('back');
const page = ref(1);
const total = ref(0);
const pagesLeft = ref(0);
const currentPage = ref(0);
const numPages = ref(0);
const btnLoad = ref(true);

const selectedOperator = ref('');
const selectedCountry = ref('');
const selectedType = ref('');
const selectedMode = ref('');

const loader = ref(false);

let token: string|null = localStorage.getItem('login_token');
let codeOrganization: string|null = localStorage.getItem('organization_code');
let codeUser: string|null = localStorage.getItem('code');
let role: string|null = localStorage.getItem('role');


const countries =ref(new Array<Country>());
const contacts =ref(new Array<Contact>());
// const reloading =ref({} as Reloading);
let edit = 0;
let itemCode = '';
let selectedOrganization = '';
let country_code = 'ci';
let user = {} as User;
const operators =ref(new Array<Operator>());

const payoutBalance =ref(0);
let balance:number = 0;

const typefees =ref(new Array<TypeFree>());
let operatorsTemp =new Array<Operator>();
let countriesTemp =new Array<Country>();
  let data = [];
 
  const errors = ref('');  
  const errorId = ref('');  
  const success = ref('');
  const errorsBis = {'phone':'','first_name':'','last_name':'','amount':0};
  const pageLoad = ref(false);

  const formValues = reactive({    
    phone: "",  
    first_name: "",
    last_name:"",
    amount:0   
  });

  
  const schema = yup.object({    
      phone: yup.string().required('Le numéro de téléphone est requis'),  
      first_name: yup.string().required('Le nom est requis'),   
      last_name: yup.string().required('Le prénom requis'),  
      amount: yup.number().required('Le montant est requis'), 
  });

  function handleAmountChange(event) {    
      let withdrawAmount = event.target.value;
      if(parseFloat(withdrawAmount)<=0){
        formValues.amount=''
      }
    }


function handleTypeChange(event) {
  selectedType.value = event.target.value;
}

function handleOperatorChange(event) {
  selectedOperator.value = event.target.value;
}


function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}
const initField = () =>{
  formValues.phone=  '';  
  formValues.first_name= ''; 
  formValues.last_name= '';
  country_code = '';
  edit=0;
}

function handlePhone(myvalue,tag) {
     country_code =iti.getSelectedCountryData().iso2;
      let phone_length=0;
      switch (country_code) {
        case 'ci':
          phone_length=process.env.VUE_APP_CI_PHONE_LENTGH;
          break;        
        case 'sn':
          phone_length=process.env.VUE_APP_SN_PHONE_LENTGH;
          break;
        default:
          alert("Sélectionnez un pays");
      }
      validate_phone_number(myvalue,tag,phone_length)

    }

const getContacts = (arg=1) =>{
    // +arg
    let param = '';
    param = (role=='ADMIN')?'':`?organization=${codeOrganization}`
    axios.get(backApi+'/api/v1/secure/contactbook/'+arg+param,{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        contacts.value = response.data.contacts;  //.data.contacts            
        data  = response.data;  
        total.value=parseInt(response.data.pagination.total);
        page.value=parseInt(response.data.pagination.page);
        pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        currentPage.value=parseInt(response.data.pagination.currentPage);
        numPages.value=parseInt(response.data.pagination.numPages);

        // total.value=10;
        // page.value=1;
        // pagesLeft.value=0;
        // currentPage.value=1;
        // numPages.value=1;
        
        // alert(total.value)
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const showReloading = (code) =>{
    // +arg
     axios.get(backApi+'/api/v1/secure/contactbook/'+code+'/show',{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        // reloading.value = response.data;  //.data.contacts 
        formValues.phone=  response.data.contact;  
        formValues.first_name=  response.data.first_name; 
        formValues.last_name=  response.data.last_name; 
        itemCode = response.data.code;
        country_code = response.data.country_code;
        edit=1;        
        console.log(response.data);
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                console.log(error.response);
              }   
              
          }
  });
}

const showTransfert = (code) =>{
    // +arg
     axios.get(backApi+'/api/v1/secure/contactbook/'+code+'/show',{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        // reloading.value = response.data;  //.data.contacts 
        formValues.phone=  response.data.contact;  
        formValues.first_name=  response.data.first_name; 
        formValues.last_name=  response.data.last_name; 
        itemCode = response.data.code;
        country_code = response.data.country_code;
        selectOperatorByCountry(country_code);
           
        console.log(response.data);
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                console.log(error.response);
              }   
              
          }
  });
}

const getOperators = () =>{

axios.get(backApi+'/api/v1/public/operators/',{  //secure
  headers: {
       
        Authorization: `Bearer ${token}`
  } 
})
.then((response) => {
    
    operatorsTemp = response.data;  //.data.users          
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            
          }   
          
      }
});

}

function selectOperatorByCountry(countryCode) {  
  var j = 0;
  var operatorArr = new Array<Operator>();
  while(j<operatorsTemp.length){    
      if(operatorsTemp[j].countryCode == countryCode){         
          operatorArr.push(operatorsTemp[j]);
      }      
      j++;
  }
   operators.value = operatorArr;
}

const getBalancePayout = () =>{

axios.post(backApi+`/api/v1/secure/accounts/balance/payout`,{//collect//collectBalance
          code: codeOrganization,         
        },{  
        headers: {
            
              Authorization: `Bearer ${token}`
        } 
})
.then((response) => {    
    payoutBalance.value = response.data.balance;  //.data.users  
    balance = response.data.balance;       
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            //window.location.href = '/';
            //router.push('/');
          }   
          
      }
});
//  loader.value=true;
}




const changeStatus =(status,code)=>{
 // alert(code)
  axios.put(`${backApi}/api/v1/secure/topups/status`,{
        code:code,      
        status:  status
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
      }       
      }).then((response) => {        
        router.go();        
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const showScreen = (code)=>{ 
  itemCode=code;
  
}

const cancel = () =>{
  itemCode="";
  errorId.value="";
  errors.value="";
}

const save =()=>{
  if (edit==0){
    storeContact()
  }else{
    updateContact()
    
  }
  
}


const sendMoney =()=>{
btnLoad.value=false;
if(parseFloat(formValues.amount)>0 && parseFloat(formValues.amount)<=balance && selectedOperator.value!==''){
try {  
  schema.validateSync(formValues);

  axios.post(`${backApi}/api/v1/secure/transactions/init`,{
    clientId:formValues.first_name+" "+formValues.last_name,    
    operator:selectedOperator.value,
    amount:parseFloat(formValues.amount),
    mobile:formValues.phone,
    operationType:parseInt(process.env.VUE_APP_PAYOUT_OPTTYPE),
    countryCode:country_code,
    successUrl: "https://billing.api/success",
    errorUrl: "https://billing.api/error"
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        btnLoad.value=true;
        if(response.status === 201){      
          //router.go();
           
          showToast("Transfert réussi","green");
          formValues.amount=0;
          formValues.phone='';
          selectedOperator.value='';          
        }      
        
    }).catch((error) => {
      if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
        console.error(error);        
         errors.value="une erreur inattendue est apparue"
      
}
}else{
  if(parseFloat(formValues.amount)==0 || parseFloat(formValues.amount)>balance){
      showToast("Veuillez saisir un montant correct","red");
  }else{
      showToast("Veuillez sélectionner un opérateur","red");
  }
  
}
btnLoad.value=true;
}

const storeContact =()=>{
  btnLoad.value=false;
try {  
  schema.validateSync(formValues);
  // alert(formValues)
  axios.post(`${backApi}/api/v1/secure/contactbook/new`,{          
          last_name:formValues.last_name,
          first_name:formValues.first_name,
          organization:codeOrganization,//,//user.organization.code, 
          user:codeUser,
          contact:formValues.phone,
          country_code:country_code.toUpperCase()        
          // type:selectedType.value
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        btnLoad.value=true;
        if(response.status === 200){ 
          console.log(response);
          //showToast("Enregistrement effectué avec succès");
          showToast("Enregistrement effectué avec succès","green");
          router.go();  
        }      
        
    }).catch((error) => {
      // alert(error.response.data.errorId);
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
        // console.log(error);        
         errors.value="une erreur inattendue est apparue"
         
              
}

}

const updateContact =()=>{
  btnLoad.value=false;
try {  
  schema.validateSync(formValues);
  // alert(formValues)
  axios.put(`${backApi}/api/v1/secure/contactbook/${itemCode}/edit`,{          
          last_name:formValues.last_name,
          first_name:formValues.first_name,
          organization:codeOrganization,
          user:codeUser,
          contact:formValues.phone,
          country_code:country_code.toUpperCase()      
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {
        edit=0;   
        itemCode='';      
        btnLoad.value=true;
        if(response.status === 200){   
          showToast("Modification effectuée avec succès","green");   
          router.go();  
        }      
        
    }).catch((error) => {
      // alert(error.response.data.errorId);
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
        // console.log(error);        
         errors.value="une erreur inattendue est apparue"
         
              
}

}

const deleteContact =()=>{
  btnLoad.value=false;
try {   
  // alert(itemCode);
  const headers = {
  'Authorization':  `Bearer ${token}`
  }
const data = {
  code: itemCode
}
  axios.delete(`${backApi}/api/v1/secure/contactbook/delete`, {headers, data})
  .then(response => {
    if(response.status === 200){ 
      btnLoad.value=true;
          console.log(response);          
          router.go();  
        }   
})
.catch(error => {
  if( error.response){
      
      if(error.response.status === 401){
        router.push('/')
        
      }else{
        errorId.value=error.response.data.errorId;
        errors.value=error.response.data.message;
      } 
      
  }
});

} catch (error) {
        // console.log(error);        
         errors.value="une erreur inattendue est apparue"
         
              
}

}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value=''
}

const exportExcel = () =>{
  
  import("../../../utils/Export2Excel").then((excel)=>{
    // const data = transactions.value;
    const Header =["CODE RECHARGEMENT","DESCRIPTION","MONTANT","DATE","STATUT"];
    const Fields = ["code","description","amount","createdAt","status"];//mnoName
    // console.log(data);
    const dataForExcel= formatJSon(Fields,data);
    // console.log(dataForExcel);
    excel.export_json_to_excel({     
      header:Header,
      data:dataForExcel,
      sheetName:"Feuil1",
      filename:"rechargement",      
      autoWidth: true,
      bookType:"xlsx",
  });
  });
}

function formatJSon(FilterData,JsonData){
  return JsonData.map((v)=>FilterData.map(j=>{
      return v[j];
    }));
}


 onMounted(async () => { 

   getContacts();
   getBalancePayout();
   getOperators();
  //  getOrganizations();
  
   setTimeout(function () {
    pageLoad.value=true; 
         },1000)

         const inputc = document.querySelector("#phone");
  // setTimeout(function () {
  //  pageLoad.value=true; 
        //  },1000);
  
  iti = intlTelInput(inputc, {
    // any initialisation options go here
    onlyCountries: ["ci","sn"],
    // i18n: ci,
    initialCountry: "auto",
  // utilsScript: "/intl-tel-input/js/utils.js?1722010922246",
  geoIpLookup: function(callback) {
    fetch("https://ipapi.co/json")
      .then(function(res) { return res.json(); })
      .then(function(data) { callback(data.country_code); })
      .catch(function() { callback("ci"); });
  }
  });
});
</script>